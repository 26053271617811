import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddressbookRecord, AddressbookRecordPhoneLabel, AddressbookService, CallHistoryRecord, DatabaseService } from '../../../core/services';
import { AddEditContactModalComponent } from '../../../contacts/add-edit-contact-modal/add-edit-contact-modal.component';
import { DisplayName } from '../../../core/sipjs/models';
import { NgbModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AddToExistingContactModalComponent } from '../add-to-existing-contact-modal/add-to-existing-contact-modal.component';

@Component({
  selector: 'add-contact-quick',
  templateUrl: './add-contact-quick.component.html',
  styleUrls: ['./add-contact-quick.component.scss']
})
export class AddContactQuickComponent implements OnInit{
  @Input() record: CallHistoryRecord;
  @Input() canDelete: boolean = false;
  @Output() dataChanged: EventEmitter<null> = new EventEmitter();
  @Output() dropdownChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() onDelete: EventEmitter<null> = new EventEmitter();
  @ViewChild(NgbDropdown, { static: true }) dropdown: NgbDropdown;

  constructor(
    private modalService: NgbModal,
    private databaseService: DatabaseService,
    private addressbookService: AddressbookService
  ) {}


  ngOnInit(): void {
    this.dropdown.openChange.subscribe((r) => {
      this.dropdownChanged.emit(r);
    })
  }

  public deleteRecord() {
    this.onDelete.emit();
  }

  public addNewContact() {
    const modal = this.modalService.open(AddEditContactModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    const newRecord = {
      type: this.record.addressbookType,
      defaultNumber: {
        label: AddressbookRecordPhoneLabel.PRIMARY,
        number: this.record.displayName.number
      },
      numbers: [{
        label: AddressbookRecordPhoneLabel.PRIMARY,
        number: this.record.displayName.number
      }],
      emails: [],
    }
    modal.componentInstance.canUpdate = true;
    modal.componentInstance.hasPhonebook = true;
    modal.componentInstance.record = newRecord;
    modal.componentInstance.contactCreated.subscribe(contact => {
      const newDisplayName: DisplayName = {
        name: {
          remote: `${contact.firstName || contact.first_name} ${contact.lastName || contact.last_name || ''}`.trim()
        },
        number: this.record.displayName.number
      }
      this.databaseService.updateAllCallHistory(this.record.displayName.number, 'displayName', newDisplayName).subscribe(r => {
        this.dataChanged.emit();
      });
    })
  }

  public addtoExisting() {
    const modal = this.modalService.open(AddToExistingContactModalComponent,{
      size: 'lg',
      backdrop: 'static'
    }
    )
    modal.componentInstance.callhistoryRecord = this.record;
  }
}
