import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressbookRecord, APIAddressbookRecord, APIAddressbookRecordPhone, AddressbookService, DatabaseService, ADDRESSBOOK_TYPES } from '../../core/services';
import { BehaviorSubject } from 'rxjs';
import { SessionDTO } from '../../account/store/states-models';
import { take } from 'rxjs/operators';
import { AccountFacade } from '../../account/store/facade';
import { NotificationService } from '../../core/services/notifications';

@Component({
  selector: 'app-add-edit-contact-modal',
  templateUrl: './add-edit-contact-modal.component.html',
  styleUrls: [
    '../../shared/styles/contact-list/contact-list.scss',
    './add-edit-contact-modal.component.scss'
  ]
})
export class AddEditContactModalComponent implements OnInit {
  public record: AddressbookRecord;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasPhonebook: boolean = false;
  public canUpdate: boolean = false;
  @Output() contactCreated: EventEmitter<APIAddressbookRecord | AddressbookRecord> = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    private accountFacade: AccountFacade,
    private addressbookService: AddressbookService,
    private notificationService: NotificationService,
    private databaseService: DatabaseService
  ) {
  }

  ngOnInit(): void {}


  public onSubmit(data): void {
    if(!this.record?.id) {
      data.saveOnRemote ? this.buildNewRemoteContact(data.formValue) : this.buildNewLocalContact(data.formValue);
    } else {
      this.record.type === ADDRESSBOOK_TYPES.EXTERNAL ? this.updateExtistingRemoteContact(data.formValue) : this.updateExistingLocalContact(data.formValue);
    }
  }

  private buildNewLocalContact(formValue): void {
    this.isLoading.next(true);
    const hasPhones = formValue.numbers.some(n => n.number);
    const hasEmails = formValue.emails.some(e => e.email);
    let contact: AddressbookRecord = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      company_name: formValue.company_name,
      job_title: formValue.job_title,
      website: formValue.website,
      address: {
        street: formValue.street,
        postal_code: formValue.postal_code,
        city: formValue.city,
        country: formValue.country,
        province: formValue.province,
        address_line: formValue.address_line
      },
      numbers: hasPhones ? formValue.numbers : [],
      defaultNumber: hasPhones ? formValue.numbers[0] : null,
      emails: hasEmails ? formValue.emails : [],
      type: ADDRESSBOOK_TYPES.LOCAL
    }
    this.databaseService.addAddressbookRecord(contact).subscribe(() => {
      this.isLoading.next(false);
      this.addressbookService.newContactAdded$.next(null);
      this.contactCreated.emit(contact);
      this.notificationService.success('feature-menu.call-view.add-edit-contact.success-add-local', {
        messageParameters: {
          name: contact.firstName
        }
      })
      this.close();
    });
  }

  private buildNewRemoteContact(formValue): void {
    this.isLoading.next(true);
    this.accountFacade.session$.pipe(take(1)).subscribe((session: SessionDTO) => {
      const hasPhones = formValue.numbers.some(n => n.number);
      const hasEmails = formValue.emails.some(e => e.email);
      let contact: APIAddressbookRecord = {
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        phones: hasPhones ? <APIAddressbookRecordPhone[]>(formValue.numbers) : null,
        addresses: [
          {
            id: formValue.id,
            country: formValue.country.toLowerCase(),
            city: formValue.city,
            postal_code: formValue.postal_code,
            province: formValue.province,
            street: formValue.street,
            address_line: formValue.address_line
          }
        ],
        emails: hasEmails ? formValue.emails : [],
        job_title: formValue.job_title,
        company_name: formValue.company_name,
        website: formValue.website
      };
      Object.keys(contact).forEach((key) => {
        if(contact[key] === null) delete contact[key]
      })
      this.addressbookService.createContact(session.company_id, session.user_id, contact).subscribe((response: APIAddressbookRecord) => {
        this.notificationService.success('feature-menu.call-view.add-edit-contact.success-add', {
          messageParameters: {
            name: contact.first_name
          }
        })
        this.isLoading.next(false);
        this.addressbookService.newContactAdded$.next(null);
        this.contactCreated.emit(contact);
        this.close();
      }, (error) => {
        this.notificationService.error('feature-menu.call-view.add-edit-contact.failure-add');
        this.isLoading.next(false);
      });
    });
  }

  private updateExistingLocalContact(formValue): void {
    this.isLoading.next(true);
    const hasPhones = formValue.numbers.some(n => n.number);
    const hasEmails = formValue.emails.some(e => e.email);
    let contact: AddressbookRecord = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      company_name: formValue.company_name,
      job_title: formValue.job_title,
      website: formValue.website,
      address: {
        street: formValue.street,
        postal_code: formValue.postal_code,
        city: formValue.city,
        country: formValue.country,
        province: formValue.province,
        address_line: formValue.address_line
      },
      id: this.record.id,
      favorite: this.record.favorite,
      numbers: hasPhones ? formValue.numbers : [],
      defaultNumber: hasPhones ? formValue.numbers[0] : null,
      emails: hasEmails ? formValue.emails : [],
      type: ADDRESSBOOK_TYPES.LOCAL
    }
    this.databaseService.updateAddressbookRecord(contact).subscribe(() => {
      this.isLoading.next(false);
      this.notificationService.success('feature-menu.call-view.add-edit-contact.success-edit');
      this.addressbookService.newContactAdded$.next(null);
      this.close(true);
    });
  }

  private updateExtistingRemoteContact(formValue): void {
    this.isLoading.next(true);
    this.accountFacade.session$.pipe(take(1)).subscribe((session: SessionDTO) => {
      const hasPhones = formValue.numbers.some(n => n.number);
      const hasEmails = formValue.emails.some(e => e.email);
      let contact: APIAddressbookRecord = {
        id: this.record.id,
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        phones: hasPhones ? <APIAddressbookRecordPhone[]>(formValue.numbers) : null,
        addresses: [
          {
            id: formValue.id,
            country: formValue.country.toLowerCase(),
            city: formValue.city,
            postal_code: formValue.postal_code,
            province: formValue.province,
            street: formValue.street,
            address_line: formValue.address_line
          }
        ],
        emails: hasEmails ? formValue.emails : [],
        job_title: formValue.job_title,
        company_name: formValue.company_name,
        website: formValue.website
      };
      Object.keys(contact).forEach((key) => {
        if(contact[key] === null) delete contact[key]
      })
      this.addressbookService.updateContact(session.company_id, session.user_id, contact.id, contact).subscribe((response: APIAddressbookRecord) => {
        this.notificationService.success('feature-menu.call-view.add-edit-contact.success-edit');
        this.isLoading.next(false);
        this.addressbookService.newContactAdded$.next(null);
        this.close(true);
      }, (error) => {
        this.notificationService.error('feature-menu.call-view.add-edit-contact.failure-edit');
        this.isLoading.next(false);
      });
    });
  }

  public close(saved: boolean = false): void {
    this.activeModal.close(saved);
  }
}
