import { DBSchema } from 'idb';

import { AddressbookRecord, FavoriteRecord} from '../addressbook';
import { CallHistoryRecord } from '../callhistory';

export const DATABASE_NAME: string = 'addressbook and history';

export const DATABASE_VERSION: number = 2;  // This should be changed manually when we want to update the database schema

export enum DBStores {
  ADDRESSBOOK_OS = 'addressbook',
  FAVORITES_OS = 'favorites',
  CALLHISTORY_OS = 'call_history'
}

export enum AddressbookIndexes {
  BY_REMOTE_ID = 'by-remote-id',
  BY_TYPE = 'by-type',
  BY_NUMBER = 'by-number',
  BY_FIRSTNAME = 'by-first-name',
  BY_LASTNAME = 'by-last-name',
  BY_HASH = 'by-hash',
  BY_FAVORITE = 'by-favorite'
}

export enum FavoriteIndexes {
  BY_CONTACT_ID = 'by-contact-id',
  BY_TYPE = 'by-type'
}

export enum CallHistoryIndexes {
  BY_NUMBER = 'by-number',
  BY_DATE = 'by-date',
  BY_TYPE = 'by-type'
}

export enum VideoconferenceIndexes {
  BY_NAME = 'by-name',
  BY_DATE = 'by-date',
  BY_TYPE = 'by-type'
}

export type DBIndexes = AddressbookIndexes | FavoriteIndexes | CallHistoryIndexes | VideoconferenceIndexes;

// Possible bug in library: Type infer will giveup if indexes are not equals...
// However this is only an interface. In the actual creation of the db, we specify which
// index to create so event though call history has by-name here, it's not actually created
export interface DatabaseSchema extends DBSchema {
  [DBStores.ADDRESSBOOK_OS]: {
    key: string,
    value: AddressbookRecord,
    // Seems like type computing breaks type infer so static string are used here.
    indexes: { 
      'by-type': string, 
      'by-number': string, 
      'by-date': Date, 
      'by-first-name': string, 
      'by-last-name': string, 
      'by-name': string, 
      'by-hash': string, 
      'by-remote-id': string, 
      'by-favorite': string ,
      'by-contact-id': string 
    } 
  },
  [DBStores.FAVORITES_OS]: {
    key: string,
    value: FavoriteRecord,
    // Seems like type computing breaks type infer so static string are used here.
    indexes: { 
      'by-type': string, 
      'by-number': string, 
      'by-date': Date, 
      'by-first-name': string, 
      'by-last-name': string, 
      'by-name': string, 
      'by-hash': string, 
      'by-remote-id': string, 
      'by-favorite': string ,
      'by-contact-id': string 
    } 
  },
  [DBStores.CALLHISTORY_OS]: {
    key: string,
    value: CallHistoryRecord,
    // Seems like type computing breaks type infer so static string are used here.
    indexes: { 
      'by-type': string, 
      'by-number': string, 
      'by-date': Date, 
      'by-first-name': string, 
      'by-last-name': string, 
      'by-name': string, 
      'by-hash': string, 
      'by-remote-id': string, 
      'by-favorite': string ,
      'by-contact-id': string 
    } 
  }
}