import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddEditEmailFormArray, AddEditEmailFormModel, AddEditFormModel, AddEditNumberFormArray, AddEditNumberFormModel } from '../../../contacts/models/contacts.model';
import { FormControl, FormGroup, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { AddressbookRecord, AddressbookRecordEmailLabel, AddressbookRecordPhoneLabel, APIAddressbookRecord } from '../../../core/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-edit-contact-form',
  templateUrl: './add-edit-contact-form.component.html',
  styleUrls: ['./add-edit-contact-form.component.scss']
})
export class AddEditContactFormComponent {
  public isCollapsed: boolean = true;
  public form: FormGroup<AddEditFormModel>;
  public numbers: AddEditNumberFormArray;
  public emails: AddEditEmailFormArray;
  public emailsLabelArray: string[] = Object.values(AddressbookRecordEmailLabel);
  public numbersLabelArray: string[] = Object.values(AddressbookRecordPhoneLabel).filter(v => v !== AddressbookRecordPhoneLabel.EXTENSION);
  public indexPrimaryNumber: number = 0;
  public indexPrimaryEmail: number = 0;
  public defaultNumber: number = 0;
  
  public defaultCountry = 'it';
  public language: string = 'it';
  private languageChangeSub: Subscription;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public saveOnRemote: boolean = false;
  @Input() hasPhonebook:boolean = false;
  @Input() canUpdate:boolean = false;
  @Input() record: AddressbookRecord;
  @Input() newContact?: string;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,      //not sure what type to use here.. this form builder is used to build different formgroups and formarrays
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.saveOnRemote = this.hasPhonebook && this.canUpdate;
    this.language = this.translateService.currentLang;
    this.languageChangeSub = this.translateService.onLangChange.subscribe((c) => {
      this.language = c.lang;
    });
    this.form = this.fb.group({
      firstName: new FormControl<string>(this.record?.firstName || null, [Validators.required]),
      lastName: new FormControl<string>(this.record?.lastName || null),
      numbers: this.buildPhonesFormArray(),
      emails: this.buildEmailFormArray(),
      street: new FormControl<string>(this.record?.address?.street),
      postal_code: new FormControl<string>(this.record?.address?.postal_code),
      province: new FormControl<string>(this.record?.address?.province),
      city: new FormControl<string>(this.record?.address?.city),
      job_title: new FormControl<string>(this.record?.job_title),
      website: new FormControl<string>(this.record?.website),
      company_name: new FormControl<string>(this.record?.company_name),
      country: new FormControl<string>(this.record?.address?.country || this.defaultCountry),
      address_line: new FormControl<string>(this.record?.address?.address_line),
      id: new FormControl<number>(this.record?.address?.id)
    });
    this.numbers = this.form.controls.numbers;
    this.emails = this.form.controls.emails;
    this.defaultNumber = this.getExistingDefaultNumber();
  }

  ngOnDestroy(): void {
    if(this.languageChangeSub) this.languageChangeSub.unsubscribe();
  }

  private buildPhonesFormArray(): AddEditNumberFormArray {
    let result: AddEditNumberFormArray = this.fb.array([]);
    if(this.record) {
      for(let number of this.record.numbers){
        result.push(this.fb.group(number))
      }
    } else {
      result.push(
        this.fb.group(
          {
            number: new FormControl<string>(''),
            label: new FormControl<AddressbookRecordPhoneLabel>(AddressbookRecordPhoneLabel.PRIMARY)
          }
        )
      )
    }
    result.addValidators(this.uniqueNumberLabaleValidator());
    return result;
  }

  private buildEmailFormArray(): AddEditEmailFormArray {
    let result: AddEditEmailFormArray = this.fb.array([]);
    if(this.record) {
      for(let email of this.record.emails){
        result.push(this.fb.group(email))
      }
    } else {
      result.push(
        this.fb.group(
          {
            email: new FormControl<string>('', Validators.email),
            label: new FormControl<AddressbookRecordEmailLabel>(AddressbookRecordEmailLabel.PRIMARY)
          }
        )
      )
    }
    result.addValidators(this.uniqueEmailLabaleValidator());
    return result;
  }

  private getExistingDefaultNumber(): number {
    let result: number;
    if(this.record) {
      const number = this.record.numbers.find(el => el.number === this.record.defaultNumber.number);
      result = this.record.numbers.indexOf(number);
    } else {
      result = 0;
    }
    return result;
  }

  public isFieldInvalid(name: string): boolean {
    return this.form.controls[name].invalid && this.form.controls[name].dirty;
  }

  public getPhoneNumberToken(index: number): string {
    if (!this.numbers.at(index).get('number').value) {
      return "feature-menu.call-view.add-edit-contact.form.required";
    } else {
      return "feature-menu.call-view.add-edit-contact.form.numbers.invalid";
    }
  }

  public addNumber(): void {
    this.numbers.push(
      this.fb.group({
        number: new FormControl<string>('',),
        label: new FormControl<AddressbookRecordPhoneLabel>(AddressbookRecordPhoneLabel.OTHER)
      })
    );
  }

  public addEmail(): void {
    this.emails.push(
      this.fb.group({
        email: new FormControl<string>('', Validators.email),
        label: new FormControl<AddressbookRecordEmailLabel>(AddressbookRecordEmailLabel.OTHER)
      })
    )
  }

  public removeEmail(controlIndex: number) {
    this.emails.removeAt(controlIndex);
    if(this.indexPrimaryEmail === controlIndex && !this.emails.invalid) {
      const c = this.emails.controls[0];
      c.controls.label.patchValue(AddressbookRecordEmailLabel.PRIMARY);
    }
  }

  public removeNumber(controlIndex: number) {
    this.numbers.removeAt(controlIndex);
    if(this.indexPrimaryNumber === controlIndex && !this.numbers.errors?.not_unique) {
      const c = this.numbers.controls[0];
      c.controls.label.patchValue(AddressbookRecordPhoneLabel.PRIMARY);
    }
  }

  public changeEmailLabel(control: FormControl<AddressbookRecordEmailLabel>, value: string): void {
    control.patchValue(AddressbookRecordEmailLabel[value]);
  }

  public changeNumberLabel(control: FormControl<AddressbookRecordPhoneLabel>, value: string): void {
    control.patchValue(AddressbookRecordPhoneLabel[value]);
  }

  public onSubmit(): void {
    this.formSubmitted.emit({formValue: this.form.value, saveOnRemote: this.saveOnRemote});
  }

  public get formInvalid(): boolean {
    let returnValue: boolean = false;
    const controlsKey: string[] = Object.keys(this.form.controls);
    controlsKey.forEach((key: string) => {
      if(key === 'address') {
        if(this.form.controls[key].errors?.place_missing && this.form.controls[key].value === null) return;
        return true;
      } else {
        if(this.form.controls[key].invalid) returnValue = true;
      }
    });
    return returnValue;
  }

  private uniqueEmailLabaleValidator(): ValidatorFn {
    return (formArray:AddEditEmailFormArray): {[key: string]: any} | null=>{
      let idWithPrimary = [];
      formArray.controls.forEach((group: FormGroup<AddEditEmailFormModel>, index: number) => {
        if(group?.controls?.label?.value === AddressbookRecordEmailLabel.PRIMARY){
          idWithPrimary.push(index);
        }
      });
      if(idWithPrimary.length > 1) {
        idWithPrimary.forEach((id: number) => {
          const group = formArray.controls[id];
          group.setErrors({"not_unique": true});
        });
        this.indexPrimaryEmail = -1;
        return {"not_unique": true};
      }
      if(idWithPrimary.length === 1) {
        this.indexPrimaryEmail = idWithPrimary[0];
      }
      formArray.controls.forEach((c: FormGroup<AddEditEmailFormModel>) => c.setErrors(null));
      return null;
    };
  }

  private uniqueNumberLabaleValidator(): ValidatorFn {
    return (formArray: AddEditNumberFormArray): {[key: string]: any} | null=>{
      let idWithPrimary = [];
      formArray.controls.forEach((group: FormGroup<AddEditNumberFormModel>, index: number) => {
        if(group?.controls?.label?.value === AddressbookRecordPhoneLabel.PRIMARY){
          idWithPrimary.push(index);
        }
      });
      if(idWithPrimary.length > 1) {
        idWithPrimary.forEach((id: number) => {
          const group = formArray.controls[id];
          group.setErrors({not_unique: true});
        });
        this.indexPrimaryNumber = -1;
        return {not_unique: true};
      }
      if(idWithPrimary.length === 1) {
        this.indexPrimaryNumber = idWithPrimary[0];
      }
      formArray.controls.forEach((c: FormGroup<AddEditNumberFormModel>) => c.setErrors(null));
      return null;
    };
  }

  doesMatch(value: string, newContact: string): boolean {
    if (!value || !newContact) return false;

    const escapedContact = newContact.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedContact);
    return regex.test(value);
  }
}
